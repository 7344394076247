body,
html,
#root{
  margin: 0;
  padding: 0;
  height: 100%;
}

.appContainer {
  margin: 0;
  padding: 0;
  /* height: 100%; */
}